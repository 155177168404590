import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Mocha Delight Coffee House
			</title>
			<meta name={"description"} content={"Де кожен ковток викликає радість"} />
			<meta property={"og:title"} content={"Mocha Delight Coffee House"} />
			<meta property={"og:description"} content={"Де кожен ковток викликає радість"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="icon1" />
			<Override slot="box3" />
			<Override slot="box2" />
		</Components.Contact>
		<Components.Nav>
			<Override slot="link4" />
			<Override slot="link2" />
		</Components.Nav>
		<Components.Hero>
			<Override slot="button" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text" />
			<Override slot="text3" />
		</Components.Hero>
		<Section padding="120px 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Text margin="0px 0px 2rem 0px" color="--darkL2" font="normal 600 46px/1.2 --fontFamily-serifGaramond" text-align="center">
				Про Mocha Delight
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
					align-items="center"
					align-content="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 0 0 2px"
						border-style="solid"
						border-color="#333b41"
						padding="0px 0px 0px 25px"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="--base" color="#333b41">
							Кав'ярня Mocha Delight - ваш затишний куточок комфорту. Створений із затишною атмосферою, це ідеальне місце, щоб поспілкуватися з друзями, попрацювати над своїм останнім проектом або просто відпочити за чашкою чудової кави. Ми прагнемо до якості, вибираємо зерна від найкращих виробників у всьому світі, щоб кожна чашка була насиченою та ароматною.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/1-2.jpg?v=2024-05-30T11:46:42.496Z"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/1-2.jpg?v=2024-05-30T11%3A46%3A42.496Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/1-2.jpg?v=2024-05-30T11%3A46%3A42.496Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/1-2.jpg?v=2024-05-30T11%3A46%3A42.496Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/1-2.jpg?v=2024-05-30T11%3A46%3A42.496Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/1-2.jpg?v=2024-05-30T11%3A46%3A42.496Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/1-2.jpg?v=2024-05-30T11%3A46%3A42.496Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/1-2.jpg?v=2024-05-30T11%3A46%3A42.496Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
					order="1"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 2px 0 0"
						border-style="solid"
						border-color="#333b41"
						padding="0px 25px 0px 0px"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="--base" color="#333b41" text-align="right">
							Наші бариста захоплені своєю справою, майстерно готуючи кожен напій до досконалості. Незалежно від того, чи ви бажаєте сміливого еспресо, вершкового лате чи освіжаючої кави з льодом, у Mocha Delight ви знайдете те, що вам сподобається. Ми також пропонуємо вибір чудової випічки та закусок, щоб доповнити ваш смак кави.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11%3A46%3A42.484Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11%3A46%3A42.484Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11%3A46%3A42.484Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11%3A46%3A42.484Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11%3A46%3A42.484Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11%3A46%3A42.484Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11%3A46%3A42.484Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="block"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Зв'яжіться з нами!
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Наша дружня команда завжди готова допомогти вам. Завітайте до нас і відчуйте тепло та гостинність кав'ярні Mocha Delight.
					<br />
					<br />
					Приходьте в кав'ярню Mocha Delight і відчуйте насолоду від ідеально звареної кави. Насолоджуйтесь нашою затишною атмосферою, смакуйте наші смачні напої та дізнайтеся, чому ми є улюбленими серед любителів кави.
				</Text>
			</Box>
		</Section>
		<Components.Services>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text4" />
			<Override slot="text6" />
			<Override slot="text3" />
			<Override slot="text5" />
			<Override slot="text7" />
			<Override slot="box9" />
			<Override slot="box6" />
			<Override slot="box3" />
			<Override slot="box2" />
			<Override slot="box" />
			<Override slot="image2" />
			<Override slot="image1" />
			<Override slot="image" />
		</Components.Services>
		<Components.Nav>
			<Override slot="link4" />
		</Components.Nav>
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});